import React, { Component } from 'react';
import { withAuthentication } from 'react-aad-msal';
import { authProvider } from '../authProvider';

export default withAuthentication(class Logout extends Component {

  render() {
    return (
      <div>
        <button className='close-session' onClick={this.props.logout}>Cerrar Sesión »</button>
      </div>
    );
  }
}, {
  provider: authProvider
});