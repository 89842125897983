import { MsalAuthProvider, LoginType } from "react-aad-msal";

const tenant = process.env.REACT_APP_B2C_TENANT;
const signInPolicy = process.env.REACT_APP_B2C_SIGN_IN_POLICY;
const applicationID = process.env.REACT_APP_B2C_APPLICATION_ID;
const reactRedirectUri = process.env.REACT_APP_B2C_REDIRECT_URI;
const tenantSubdomain = tenant.split(".")[0];
const instance = `https://${tenantSubdomain}.b2clogin.com/tfp/`;
const signInAuthority = `${instance}${tenant}/${signInPolicy}`;
const appReadScope = process.env.REACT_APP_B2C_SCOPE_READ;
const appWriteScope = process.env.REACT_APP_B2C_SCOPE_WRITE;

// Msal Configurations
const signInConfig = {
    auth: {
        authority: signInAuthority,
        clientId: applicationID,
        redirectUri: reactRedirectUri,
        validateAuthority: false
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true
    }
};

// Authentication Parameters
const authenticationParameters = {
    scopes: [
        appReadScope,
        appWriteScope
    ]
};

// Options
const options = {
    loginType: LoginType.Redirect
};

export const authProvider = new MsalAuthProvider(
    signInConfig,
    authenticationParameters,
    options
);