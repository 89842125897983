import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { AzureAD, AuthenticationState } from "react-aad-msal"
import { authProvider } from "./authProvider"
import jwt from 'jwt-decode'
import Utilizaciones from './pages/Utilizaciones'
import Header from './components/Header/header'
import Footer from './components/Footer/footer'

ReactDOM.render(
    <AzureAD provider={authProvider} forceLogin={true}>
        {
            ({ login, logout, authenticationState, error, accountInfo }) => {
                switch (authenticationState) {
                    case AuthenticationState.Authenticated:
                        const tokenData = jwt(accountInfo.jwtIdToken);
                        window.localStorage.setItem('email', tokenData.emails[0]);
                        return (
                            <div>
                                <Header logout={logout} />
                                <Utilizaciones />
                                <Footer />
                            </div>
                        );
                    case AuthenticationState.Unauthenticated:
                        if (error && error.errorMessage.indexOf('AADB2C90118') > -1) {
                            const tenant = process.env.REACT_APP_B2C_TENANT;
                            const tenantSubdomain = tenant.split(".")[0];
                            const passwordResetPolicy = process.env.REACT_APP_B2C_RESET_PASSWORD_POLICY;
                            const authority = `https://${tenantSubdomain}.b2clogin.com/${tenant}/${passwordResetPolicy}`;
                            authProvider.loginRedirect({ authority })
                        }
                    case AuthenticationState.InProgress:
                        return (<p>Authenticating...</p>);
                }
            }
        }
    </AzureAD>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
