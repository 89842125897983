import React from 'react'
import './alert.css'

/**
* @return al formato moneda
*/
const formatter = new Intl.NumberFormat('es-CO', {
  minimumFractionDigits: 0
})

function Alert (props) {
  const { totalDiscount, totalAccount, discount, cleanAllTransactionData, userData } = props
  return (

    <div className='shipping-alert transactions-alert'>
      <p>¡Registro de transacción exitosa!</p>
      <div className='close-alert' onClick={() => cleanAllTransactionData()}>x</div>

      <p className='title'>Información de la transacción</p>
      <p className='name'>{userData.nombreCliente}</p>
      <p className='type'>Socio {userData.segmento}</p>

      <ul>
        <li><span>Valor $</span><span>{formatter.format(totalAccount)}</span></li>
        <li><span>Descuento $</span><span>{formatter.format(discount)}</span></li>
        <li><span>Valor Total $</span><span>{formatter.format(totalDiscount)}</span></li>

      </ul>

    </div>

  )
}

export default Alert
