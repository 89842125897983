import React from 'react'
import './linkHome.css'

function LinkHome () {
  return (
    <a className='link-home' href='https://www.clubvivamos.com'>Volver a el Tiempo Vivamos  »</a>
  )
}

export default LinkHome
