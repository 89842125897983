
import React, { Component } from 'react'
import './header.css'
import '../css/bootstrap-grid.css'
import Logout from '../Logout'
import { withAuthentication } from 'react-aad-msal';
import { authProvider } from '../../authProvider.js';

export default withAuthentication(class Header extends Component {

  render() {
    return (
      <header className='header-utilization' >
        <div className='container'>
          <div className='ImagenLogo'> <a href='https://www.clubvivamos.com' /></div>
          <p className='title'>
            TRANSACCIONES CON CLUB DE SUSCRIPTORES EL TIEMPO VIVAMOS
          </p>
          <Logout logout={this.props.logout} />
        </div>
      </header>
    )
  }

}, {
  provider: authProvider
})