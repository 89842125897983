
import React from 'react'
import './footer.css'

function Footer () {
  return (
    <footer className='footer-utilization'>

      <p>¿Necesitas ayuda? Contactanos a nuestro PBX: 57 (1) 2940100 Ext 2862 - 2871</p>

    </footer>
  )
}

export default Footer
