import React, { Component } from 'react'
import '../components/LoginForm/login.css'
import LinkHome from '../components/LinkHome/LinkHome'
import Alert from '../components/Alert/Alert'
import axios from 'axios'
import { withAuthentication } from 'react-aad-msal';
import { authProvider } from '../authProvider.js';

const BACO_BASE_URL = process.env.REACT_APP_BACO_BASE_URL
const USER_DATA_SERVICE = process.env.REACT_APP_USER_DATA_SERVICE
const BENEFITS_FOR_USER_SERVICE = process.env.REACT_APP_BENEFITS_FOR_USER_SERVICE
const REGISTER_BENEFIT_SERVICE = process.env.REACT_APP_REGISTER_BENEFIT_SERVICE

export default withAuthentication(class Utilizaciones extends Component {

  state = {
    userData: [],
    userDataMessage : [],
    benefits: [],
    transaction: [],
    document: '',
    errorDocument: false,
    documentType: 1,
    alliedEmail: '',
    selectBenefit: '',
    errorBenefit: false,
    activeValue: true,
    totalAccount: '',
    totalAccountMessage: '',
    errorTotalAccount: false,
    errorTotalAccountCount: false,
    discount: '',
    discountPercentage: 0,
    totalDiscount: '',
    accesToken: ''

  }

  async componentDidMount () {
    // console.log(JSON.parse(window.localStorage.getItem('okta-token-storage')).accessToken.accessToken)
    // en el momento que exista aliados en las siguientes dos lineas se descomentan
    const alliedEmail = window.localStorage.getItem('email');
    this.setState({ alliedEmail })
    // this.setState({ alliedEmail: 'gceron_hrcb@telecom.com.co' }) // medida temporal mientras hay un aliado con beneficios en okta
    // this.setState({ accesToken: this.props.auth.getAccessToken() }) // set del access token necesario para okta
  }

  /**
   * Metodo que realiza peticion ajax para traer datos del suscriptor
   * @author Camilo Urrego <josurr@eltiempo.com>
   *
   * @public
   */
  getUserData = async (documentType, document) => {
    const tokenResponse = await authProvider.getAccessToken();
    const accessToken = tokenResponse.accessToken;
    if (this.state.document.length !== 0) {
      axios.get(`${BACO_BASE_URL}/${USER_DATA_SERVICE}` + documentType + '/' + document, {
        headers: {
          Authorization: 'Bearer ' + accessToken
        }
      })
        .then(res => {
          const userData = res.data.data
          if (typeof userData !== 'undefined') {
            this.setState({
              userData,
              userDataMessage : userData
            })
            if (userData.length !== 0) {
              this.getBenefits()
            }

          } else {
            this.setState({
              userData: {
                'nombreCliente': 'No registrado',
                'segmento': 'Sin segmento',
                'estado': 'Inactivo'
              }
            })
          }

        }).catch(error => {
        console.log(error)
      })
    } else {
      this.setState({ errorDocument: true })
    }

  }

  getUserDataKeyDown = (e) => {
    if(e.key === 'Enter'){
      this.getUserData(this.state.documentType, this.state.document)
    }
  }

  /**
   * Metodo que realiza peticion ajax para traer beneficios del suscriptor
   * @author Camilo Urrego <josurr@eltiempo.com>
   *
   * @public
   */
  getBenefits = async () => {
    const tokenResponse = await authProvider.getAccessToken();
    const accessToken = tokenResponse.accessToken;
    axios.get(`${BACO_BASE_URL}/${BENEFITS_FOR_USER_SERVICE}` + this.state.alliedEmail, {
      headers: {
        Authorization: 'Bearer ' + accessToken
      }
    })
      .then(res => {
        if (typeof res.data.data !== 'undefined') {
          const benefits = res.data.data
          this.setState({ benefits })
        }
      }).catch(error => {
      console.log(error)
    })
  }

  /**
   * Metodo que realiza peticion ajax de resgitro de beneficio (por ahora es un mock)
   * @author Camilo Urrego <josurr@eltiempo.com>
   *
   * @public
   */
  registerBenefit = async () => {
    const tokenResponse = await authProvider.getAccessToken();
    const accessToken = tokenResponse.accessToken;
    axios.post(`${BACO_BASE_URL}/${REGISTER_BENEFIT_SERVICE}`, {
      typeDocument: this.state.documentType,
      clientDocument: this.state.document,
      emailAlly: this.state.alliedEmail,
      idBenefit: this.state.selectBenefit,
      idTypeSegment: this.state.userData.idSegmento,
      totalAccount: this.state.totalAccount,
      totalDiscount: this.state.totalDiscount,
      discount: this.state.discountPercentage
    }, {
      headers: {
        Authorization: 'Bearer ' + accessToken
      }
    })
      .then(response => {
        if (response.data.length !== 0) {
          this.setState({ transaction: response.data })
          this.cleanBasicTransactionData()
        } else {
          let responseObj = { 'code': response.status }
          response.data = responseObj
          this.setState({ transaction: response.data })
        }

      })
      .catch(error => {
        let responseObj = { 'code': error.response.status }
        error.response.data = responseObj
        console.error('error en el registro del beneficio => ', error.response)
        this.setState({ transaction: error.response.data })
      })

  }

  /**
   * Actualiza estado del input de document
   *
   * @param {string} updateValue
   * @returns {none}
   */
  updateDocumentInputValue = (e) => {
    this.cleanAllTransactionData()
    let error
    if (e.target.value.length === 0) {
      error = true
    } else {
      error = false
    }
    this.setState({
      document: e.target.value,
      errorDocument: error
    })
  }

  /**
   * Actualiza estado del input de precio final del beneficio
   *
   * @param {string} updateValue
   * @returns {none}
   */
  updateTotalInputValue = (e) => {
    let error, errorCount
    error = e.target.value.length === 0
    errorCount = e.target.value.length > 9
    this.setState({
      totalAccount: e.target.value,
      totalAccountMessage: e.target.value,
      errorTotalAccount: error,
      errorTotalAccountCount: errorCount
    })
  }

  /**
   * Actualiza estado del select tipo de documento
   *
   * @param {string} updateValue
   * @returns {none}
   */
  updateValueOfSelectDocumentType = (e) => {
    this.setState({
      documentType: e.target.value
    })
  }

  /**
   * Actualiza estado del select de beneficio
   *
   * @param {string} updateValue
   * @returns {none}
   */
  updateValueOfSelectBenefit = (e) => {
    let values = e.target.value.split(',')
    let error
    if (e.target.value.length === 0) {
      error = true
    } else {
      error = false
    }
    this.setState({
      selectBenefit: parseInt(values[0]),
      discountPercentage: parseInt(values[1]),
      errorBenefit: error,
      activeValue: error
    })
  }

  validateActivateSubmit () {
    if (
      this.state.errorDocument === true ||
      this.state.errorBenefit ||
      this.state.errorTotalAccount ||
      this.state.errorTotalAccountCount ||
      this.state.totalAccount <= 0 ||
      this.state.totalAccount.length === 0 ||
      (typeof this.state.transaction.code !== 'undefined' && this.state.transaction.code === 201)
    ) {
      return true
    }
  }

  submitForm = (e) => {
    e.preventDefault()
    this.registerBenefit()
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  cleanAllTransactionData = () => {
    this.setState({
      userData: [],
      userDataMessage: [],
      benefits: [],
      transaction: [],
      selectBenefit: '',
      totalAccount: '',
      discount: '',
      discountPercentage: 0,
      totalDiscount: '',
      totalDiscountMessage : '',
      activeValue: true
    })
  }

  cleanBasicTransactionData = () => {
    this.setState({
      userData: [],
      benefits: [],
      documentType: 1,
      selectBenefit: '',
      discountPercentage: 0,
      totalAccount: '',
      activeValue: true
    })
  }

  calculateDiscount = () => {

    let discount = (parseInt(this.state.discountPercentage) * this.state.totalAccount) / 100
    let totalDiscount = Math.round(parseInt(this.state.totalAccount) - discount)

    this.setState({
      totalDiscount : totalDiscount,
      discount: discount
    })
  }

  render () {
    const {
      userData,
      userDataMessage,
      benefits,
      transaction,
      document,
      documentType,
      totalDiscount,
      totalAccount,
      totalAccountMessage
    } = this.state

    return (

      <div className='container container-utilization'>
        <form>
          <div className='row'>
            <div className='col-4'>
              <LinkHome/>
            </div>
          </div>

          {typeof transaction.code !== 'undefined' && (transaction.code === 200 || transaction.code === 201) ?
            <div className='row'>
              <Alert totalDiscount={totalDiscount}
                     totalAccount={totalAccountMessage}
                     discount={this.state.discount}
                     userData={userDataMessage}
                     cleanAllTransactionData={this.cleanAllTransactionData}/>
            </div>
            : null}

          {typeof transaction.code !== 'undefined' && (transaction.code >= 400 || transaction.code === 204 ) ?
            <div className="alert-message">

              <p>Falló la transacción. Por favor, intentálo más tarde.</p>
              <div className="close-alert" onClick={this.cleanAllTransactionData}>x</div>

            </div>
            : null}

          <div className='row transactions'>
            <div className='col-12 col-sm-10 col-md-7 col-lg-5'>
              <p className='title'>Aplicar beneficios a socio</p>
              <div className='row'>
                <div className='field-id'>
                  <p>Tipo y número de documento</p>
                  <div className='select-id '>
                    <select className='select' onChange={this.updateValueOfSelectDocumentType} value={documentType}
                            required={true}>
                      <option value='1'>C.C. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   Cédula de Ciudadanía</option>
                      <option value='2'>C.E. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    Cédula de Extranjería</option>
                      <option value='0'>NIT   </option>
                      <option value='4'>T.P.  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    Tarjeta de Pasaporte</option>
                      <option value='3'>T.I.  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   Tarjeta de Identidad</option>
                    </select>
                  </div>
                  <div className='input-search-id'>
                    <input className='input-id'
                           required={true}
                           type={'number'}
                           placeholder='No. de documento'
                           value={document}
                           onChange={this.updateDocumentInputValue}
                           onKeyDown={this.getUserDataKeyDown}
                    />
                    <div className='icon-search' onClick={() => this.getUserData(documentType, document)}></div>
                    <span className='alert-error' style={{ display: this.state.errorDocument ? 'block' : 'none' }}>Número de documento requerido</span>
                  </div>


                </div>
              </div>
              <div className='row'>
                <ul className='information'>
                  <li>Nombre: <span>{userData.nombreCliente}</span></li>
                  <li>Estado: <span>{userData.estado}</span></li>
                  <li>Segmento: <span>{userData.segmento}</span></li>
                </ul>
              </div>
              <div className='row'>
                <div className='field-benefits'>
                  <p>Beneficio</p>
                  <div className={'select ' + ((userData.length === 0 || userData.estado === 'Inactivo' )? 'disabled': '')}>
                    <select
                      required={true}
                      onChange={this.updateValueOfSelectBenefit}
                      disabled={(userData.length === 0 || userData.estado === 'Inactivo')}
                    >
                      <option value=''>Selecciona un beneficio</option>
                      {
                        Object.keys(benefits).map((index) =>
                          <optgroup key={benefits[index].id} label={benefits[index].nombre}>
                            {
                              Object.keys(benefits[index].lista).map((subindex) =>
                                <option key={subindex}
                                        value={[benefits[index].lista[subindex].id, benefits[index].lista[subindex].descuento]}
                                        percentage={benefits[index].lista[subindex].descuento}
                                >
                                  {benefits[index].lista[subindex].descripcionDescuento + ' ' + benefits[index].lista[subindex].titulo}</option>)
                            }
                          </optgroup>
                        )
                      }
                    </select>
                  </div>

                  <span className='alert-error' style={{ display: this.state.errorBenefit ? 'block' : 'none' }}>Elija beneficio a redimir</span>
                </div>
              </div>
              <div className='row'>
                <div className={'field-value ' + (this.state.errorTotalDiscountCount ? 'error-form' : '') }>
                  <p>Valor de la compra en COP</p>
                  <span> $ </span>
                  <input className={'input-value ' + (this.state.activeValue ? 'disabled ' : '' ) }
                         type={'number'}
                         required={true}
                         value={totalAccount}
                         placeholder='Escribe un valor'
                         disabled={(this.state.selectBenefit.length === 0  ? true : false)}
                         onChange={this.updateTotalInputValue}
                         onBlur={this.calculateDiscount}
                  />
                  <span className='alert-error' style={{ display: this.state.errorTotalAccount ? 'block' : 'none' }}> Valor de la compra requerido </span>
                  <span className='alert-error'
                        style={{ display: this.state.errorTotalAccountCount ? 'block' : 'none' }}> Ingrese mínimo 1 máximo 9 cifras </span>
                  <p>Valor de la compra antes del descuento</p>
                </div>
              </div>
              <div className={'input-register ' + ((this.validateActivateSubmit() === true) ? 'disabled-input' : null)}>
                {(this.validateActivateSubmit() === true) === true ?
                  <div >Registrar transacción</div>
                  :
                  <div onClick={this.submitForm}>Registrar transacción</div>
                }
              </div>
            </div>
          </div>
        </form>
      </div>

    )
  }
}, {
  provider: authProvider
})
